import Logo from '../images/logo.png';
import Nala from '../images/nala.jpg';
import Fuchur from '../images/fuchur.jpg';
import StarStable from '../images/star-stable.png';
import Death from '../images/death.svg';

export const Home = () => {
  return (
    <div className="page-wrap">
      <h1>JAWOOOOHL JUNGE!</h1>
      <h2>and Welcome!</h2>
      <br />
      <br />

      <div className="flex-row">
        <img src={Death} alt="Death" className="death-icon" />
        <span>{'WARNING: This page now has a '}<a href="/ttl">TTL</a></span>
      </div>
      <br />
      <br />

      <div className="flex-row">
        <img src={Logo} alt="Logo" className="horse-logo" />
        <span>So I heard Lara is this super cute German girl who's obsessed with horses...</span>
      </div>
      <br />
      <br />

      <span>This is her horsing around (get it!?) in her cowboy boots with Fuchur:</span>
      <br />
      <img src={Fuchur} alt="Fuchur" className="border-radius" />
      <br />
      <br />

      <span>And this is Nala, who looks like she likes to stirrup (get it!?) trouble:</span>
      <br />
      <img src={Nala} alt="Nala" className="border-radius" />
      <br />
      <br />

      <span>
        {'Apparently Lara is also obsessed with this game called '}
        <a href="https://www.starstable.com/" target="_blank" rel="noreferrer">star stable</a>
        ...
      </span>
      <br />
      <img src={StarStable} alt="StarStable" className="star-stable-logo" />
      <br />
      <br />

      <h2>This is a cool website, huh?</h2>
      <span>If your name is Lara (yes, you!) and you think this website looks like horse shit (it does!), then you should talk to Andrew (that's me!) and maybe he will teach you how to change it 😉</span>
      <br />
      <br />
      <span>Made with 💜</span>
    </div>
  );
};
